import { ThemeProvider } from '@mui/material/styles';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useBeforeunload } from 'react-beforeunload';

import { AppStateProvider } from '@/utils/AppState';
import { initDatadog } from '@/utils/Datadog';
import { campaignParams } from '@/utils/Functions';

import GlobalStyle from '../styles/globals';
import theme from '../styles/theme';

const LDProvider = dynamic(() => import('@/components/LDProvider/LDProvider'), {
  ssr: false,
});

initDatadog();

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  /*
   useBeforeunload: 
   Some browsers used to display the returned string in the confirmation dialog, 
   enabling the event handler to display a custom message to the user.However, 
   this is deprecated and no longer supported in most browsers.
   */
  useBeforeunload(
    () =>
      'Are you sure you want to perform this action, because all data will be lost?'
  );

  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    const handleRouteChange = () => {};

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => campaignParams(), []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <AppStateProvider>
          <LDProvider>
            <GlobalStyle altBackground={pageProps.altBackground} />
            <Component {...pageProps} />
          </LDProvider>
        </AppStateProvider>
      </ThemeProvider>
    </>
  );
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps };
};

export default MyApp;
